// @flow

import CatchEmAllChallengeView from './catch-them-all/CatchEmAllChallengeView.react';
import MasterYourselfChallengeView from './master-yourself/MasterYourselfChallengeView.react';
import MasterTheEnemyChallengeView from './master-the-enemy/MasterTheEnemyChallengeView.react';
import XDiffChallengeView from './x-diff/XDiffChallengeView.react';
import LowHangingFruitChallengeView from './low-hanging-fruit/LowHangingFruitChallengeView.react';
import LeaderboardView from './leaderboard/LeaderboardView.react';

import * as React from 'react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
  challenge: string,
};

function ChallengeRenderer(props: Props): React.Element<any> {
  switch (props.challenge) {
    case 'catch-em-all':
      return <CatchEmAllChallengeView lookupTarget={props.lookupTarget} />;
    case 'master-yourself':
      return <MasterYourselfChallengeView lookupTarget={props.lookupTarget} />;
    case 'master-the-enemy':
      return <MasterTheEnemyChallengeView lookupTarget={props.lookupTarget} />;
    case 'x-diff':
      return <XDiffChallengeView lookupTarget={props.lookupTarget} />;
    case 'low-hanging-fruit':
      return <LowHangingFruitChallengeView lookupTarget={props.lookupTarget} />;
    case 'leaderboard':
      return <LeaderboardView lookupTarget={props.lookupTarget} />;
    default:
      // TODO: remove this once we have more than 1 challenge
      return <CatchEmAllChallengeView lookupTarget={props.lookupTarget} />;
  }
}

export default ChallengeRenderer;
