import MasterYourselfHeaderCard from './MasterYourselfHeaderCard.react';
import React from 'react';
import MasterYourselfMasteryList from './MasterYourselfMasteryList.react';
import {useMemo, useContext} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function MasterYourselfChallengeView(props: Props): React.Element {
  const { challengesData, masteryData } = useContext(ChallengesDataContext);

  const currentLevel = challengesData.master_yourself.current_level;
  const nextLevel = challengesData.master_yourself.next_level;
  const level4ChampionsCount = useMemo(
    () => masteryData.filter(champion => champion.level === 4).length,
    [masteryData],
  );

  return (
    <div className={"challengeViewContainer"}>
      <MasterYourselfHeaderCard
        currentLevel={currentLevel}
        nextLevel={nextLevel}
        description={challengesData.master_yourself.description}
        iconURL={challengesData.master_yourself.current_level_icon}/>
      <MasterYourselfMasteryList
        summoner={props.lookupTarget.summoner}
        amountToShow={Math.max(nextLevel.value - currentLevel.value + 10, level4ChampionsCount)}
      />
    </div>
  );
}

export default MasterYourselfChallengeView;
