import React from 'react';
import ChallengeDetailsHeaderCard from '../ChallengeDetailsHeaderCard.react';
import Typography from '@mui/material/Typography';

function XDiffHeaderCard(): React.Element {
  return (
    <ChallengeDetailsHeaderCard 
      title="Diff Challenges" 
      currentLevel={''}
      nextLevel={''}
      moreDetails={
        <Typography variant="body2" gutterBottom component="div">
          Win games as different roles on the rift
        </Typography>
      }
      borderClass={"ratedBorder NONE"}
    />
  );
}

export default XDiffHeaderCard;
