import XDiffHeaderCard from './XDiffHeaderCard.react';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MasteryRowProgress from '../mastery-list/MasteryRowProgress.react'
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {useContext} from 'react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function XDiffChallengeView(props: Props): React.Element {
  const { challengesData } = useContext(ChallengesDataContext);

  const diffChallenges = { 
      "Top Diff": challengesData.top_diff, 
      "Jungle Diff": challengesData.jungle_diff, 
      "Mid Diff": challengesData.mid_diff, 
      "Bot Diff": challengesData.bot_diff, 
      "Support Diff": challengesData.support_diff,
  };

  return (
    <div className={"challengeViewContainer"}>
      <XDiffHeaderCard />
      <div className={"DiffChallengeCardContainer"}>
        {Object.keys(diffChallenges).map(diff => 
          <DiffCard 
            title={diff} 
            currentLevel={diffChallenges[diff].current_level} 
            nextLevel={diffChallenges[diff].next_level}
            iconURL={diffChallenges[diff].current_level_icon}
            key={diff} />
        )}
      </div>
    </div>
  );
}

const GAINED_POINTS = {"iron": 5, "bronze": 5, "silver": 5, "gold": 10, "platinum": 15, "diamond": 20, "master": 40};

function DiffCard(
  props: 
    {
      title: string, 
      currentLevel: {level: string, value: number}, 
      nextLevel: {level: string, value: number},
      iconURL: String,
    },
): React.Element {
  const currentLevel = props.currentLevel == null ? {level: "None", value: 0} : props.currentLevel;
  const nextLevel = props.nextLevel;

  const currentLevelFormatted =
    currentLevel.level[0] + currentLevel.level.substring(1).toLowerCase();
  
  const nextLevelFormatted = nextLevel.level[0] + nextLevel.level.substring(1).toLowerCase();
  const addedPoints = GAINED_POINTS[nextLevel.level.toLowerCase()] ?? "0";

  return (
    <Card className={"DiffChallengeCard ratedBorder " + currentLevel.level.toUpperCase()}>
      {currentLevel.level.toLowerCase() !== "none" ? <div className='ChallengeIconContainer'>
        <img className={'ChallengeIcon ' + currentLevel.level.toUpperCase() + ' ratedBoxshadow'}  src={props.iconURL} alt=''/>
      </div> : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" component="div">
          <div>
            Current Level: <Box className={currentLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{currentLevelFormatted} ({currentLevel.value} wins)</Box>
          </div>
        </Typography>
        <Typography variant="body2" component="div">
          <div>
            Wins to advance to <Box className={nextLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{nextLevelFormatted}</Box>: <Box fontWeight='bold' display='inline'>{nextLevel.value - currentLevel.value}</Box>
          </div>
        </Typography>
        <div className={"DiffChallengeCardPCTProgress"}>
          <MasteryRowProgress progressPCT={currentLevel.value / nextLevel.value} colorClass={nextLevel.level.toUpperCase() + " ratedBackground"}/>
        </div>
        <Typography variant="subtitle2" component="div">
        <div>
          (+{addedPoints} points)
        </div>
      </Typography>
      </CardContent>
    </Card>
  );
}

export default XDiffChallengeView;
