import { styled } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  color: theme.palette.common.white,
  ":hover": {
    color: "white",
  },
  '&$active': {
    color: 'white',
  },
  "&.Mui-active": {
    "&&": {
      color: "white",

      "& * ": {
        color: "white"
      }
    }
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow,
)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "rgb(26,32,44)",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "rgb(20, 39, 65)",
  },
  // hide last border
    '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&': {
    color: "white",
  }
}));
