import React from 'react';
import LeaderboardChallengesGrid from './LeaderboardChallengesGrid.react';
import {useState} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {useContext} from 'react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
  challengesToShow: [],
  sortBy: ?String,
  shownGamers: Array<String>,
};

function LeaderboardChallenges(props: Props): React.Element {
  const [filterValue, setFilterValue] = useState("");
  const { staticSummonerData } = useContext(ChallengesDataContext);
  const summoner = staticSummonerData?.current_summoner;
  return (
    <div className={"LeaderboardChallenges"}>
      <input 
        type="text"
        value={filterValue} 
        className={"LeaderboardChallengeFilter"} 
        placeholder='Search for a challenge' 
        onChange={event => setFilterValue(event.target.value)} />
      <LeaderboardChallengesGrid 
        challengesToShow={
          applyFilters(
            props.challengesToShow.filter(
              challenge => challenge.title.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 || challenge.description.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
            ),
            props.sortBy,
            summoner,
          )}
        lookupTarget={props.lookupTarget}
        shownGamers={props.shownGamers}
      />
    </div>
  );
}

function applyFilters(challenges: [], sort: ?String, summoner: {}): [] {
  if (sort == null) {
    return challenges;
  }

  return challenges.sort((a,b) => {
    const sortedA = Object.keys(a.leaderboard).sort((aa,bb) => {
      if (a.leaderboard[bb] == null) {
        return -1;
      } else if (a.leaderboard[aa] == null) {
        return 1;
      }
      return a.leaderboard[bb].value - a.leaderboard[aa].value;
    });

    const sortedB = Object.keys(b.leaderboard).sort((aa,bb) => {
      if (b.leaderboard[bb] == null) {
        return -1;
      } else if (b.leaderboard[aa] == null) {
        return 1;
      }
      return b.leaderboard[bb].value - b.leaderboard[aa].value;
    });


    const diff = sortedB.indexOf(summoner?.name) - sortedA.indexOf(summoner?.name);

    if (sort === "Highest Ranked") {
      return -1 * diff;
    } else {
      return diff;
    }
  });
}

export default LeaderboardChallenges;
