import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import BackButton from "../top-level/BackButton.react";

type Props = {
  label: string,
  onClick: () => void,
  summoner: string
};

function SummonerFetchError(props: Props): React.Element {
  return (
    <div className={"backButtonContainer"}>
      <Alert severity="error" className={"error"}>
        <AlertTitle>
          {"There was an error fetching " + props.summoner + "'s stats"}
        </AlertTitle>
        Double check your spelling or look for another summoner
      </Alert>
      <BackButton label={props.label} onClick={props.onClick} />
    </div>
  );
}
export default SummonerFetchError;
