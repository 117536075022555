import React from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {useContext} from 'react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

const TIER_ICONS = {
  none: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_none.png",
  iron: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_iron.png",
  bronze: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_bronze.png",
  silver: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_silver.png",
  gold: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_gold.png",
  platinum: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_platinum.png",
  diamond: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_diamond.png",
  master: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_master.png",
  grandmaster: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_grandmaster.png",
  challenger: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-static-assets/global/default/challenges-shared/crystal_challenger.png",
};

function LeaderboardAccountHeader(props: Props): React.Element {
  const { staticSummonerData } = useContext(ChallengesDataContext);
  const summoner = staticSummonerData?.current_summoner;

  return (
    <div className={"LeaderboardAccountHeader"}>
      <div className='LeaderboardAccountHeaderIconContainer'>
        <img className='LeaderboardAccountHeaderIconTier' src={TIER_ICONS[String(summoner?.tier ?? 'none').toLowerCase()]} alt='' />
        <img className={'LeaderboardAccountHeaderIcon ' + String(summoner?.tier ?? 'none').toUpperCase() + ' LeaderboardChallengeMainIconBoxshadow'} src={summoner?.icon} alt=''/>
        <div className='LeaderboardAccountHeaderIconLevelIndicator'> {summoner?.summoner_level}</div>
      </div>
      <div className='LeaderboardAccountContainer'>
        <div className='LeaderboardAccountHeaderTitleContainer'>
          <h1 className='LeaderboardAccountHeaderTitle'>{summoner?.name}</h1> · 
          <div className={'LeaderboardAccountHeaderFlareTitle ratedText ' + String(summoner?.tier ?? 'none').toUpperCase()}>{summoner?.title}</div>
        </div>
        <div className='LeaderboardAccountHeaderTitleIconContainer'>
          {(summoner?.challenge_tokens_urls ?? []).map((token, index) => 
            <img key={index} className={'LeaderboardAccountHeaderTitleItemIcon ' + String(token.level).toUpperCase() + ' LeaderboardChallengeItemIconBoxshadow'} src={token.icon_url} alt='' />  
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaderboardAccountHeader;
