import React from 'react';
import Box from '@mui/material/Box';
import ChallengeDetailsHeaderCard from '../ChallengeDetailsHeaderCard.react';
import Typography from '@mui/material/Typography';

type Props = {
  currentLevel: {level: string, value: number},
  nextLevel: {level: string, value: number},
  description: string,
  missingChamps: number,
  iconURL: String,
};

function MasterTheEnemyHeaderCard(props: Props): React.Element {
  const currentLevel = props.currentLevel == null ? {level: "None", value: 0} : props.currentLevel;
  const nextLevel = props.nextLevel;

  const currentLevelFormatted =
    currentLevel.level[0] + currentLevel.level.substring(1).toLowerCase();
  const nextLevelFormatted =
    nextLevel.level[0] + nextLevel.level.substring(1).toLowerCase();
  
  return (
    <ChallengeDetailsHeaderCard 
      title="Master The Enemy" 
      currentLevel={ 
        <div>
          Current Level: <Box className={currentLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{currentLevelFormatted}</Box>
        </div>
      }
      nextLevel={
        <div>
          Minimum bar to reach <Box className={nextLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{nextLevelFormatted}: {Number(nextLevel.value).toLocaleString()}</Box>
        </div>
      }
      moreDetails={
        <Typography variant="body2" gutterBottom component="div">
          You are <Box fontWeight='bold' display='inline'>{Number(props.nextLevel.value - currentLevel.value).toLocaleString()}</Box> champions away from upgrading to the next level
        </Typography>
      }
      description={props.description}
      icon={
        currentLevel.level.toLowerCase() !== "none" ? 
          <div className='ChallengeDetailsIconContainer'>
            <img className={'LHFChallengeIcon ' + currentLevel.level.toUpperCase() + ' ratedBoxshadow'}  src={props.iconURL} alt=''/>
          </div>
        : null
      }
      borderClass={"ratedBorder " + currentLevel.level.toUpperCase()}
    />
  );
}

export default MasterTheEnemyHeaderCard;
