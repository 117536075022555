import Button from '@mui/material/Button';
import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';

type Props = {
  label: string,
  onClick: () => void,
};

function BackButton(props: Props): React.Element {
  return (
    <Button
      className={"backButton"}
      onClick={() => props.onClick()}
      variant="contained"
      size="small"
      startIcon={<ArrowBack />}>
      {props.label}
    </Button>
  );
}
export default BackButton;
