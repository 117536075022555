import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';

function LoadingSpinner(): React.Element {
  return (
    <div className={"spinnerContainer"}>
      <Box sx={{ display: 'flex' }} className={"spinner"}>
        <CircularProgress color="secondary" />
      </Box>
    </div>
  );
}

export default LoadingSpinner;
