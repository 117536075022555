import LeagueOfChallenges from './LeagueOfChallenges.react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import {useEffect, useState} from 'react';
import React from 'react';
import ChampionDataContext from './ChampionDataContext.react';
import ChallengesDataContext from './ChallengesDataContext.react';

type ChallengesData = {
  CatchEmAll: {
    current_level: ?{level: string, value: number},
    description: string,
    next_level: ?{level: string, value: number},
    short_description: string,
  },
};

const API_URL = "https://league-of-challenge-backend-g7dlxidqkq-uc.a.run.app/challenges/{region}/{name}";
const LEADERBOARD_URL = "https://league-of-challenge-backend-g7dlxidqkq-uc.a.run.app/challenges/leaderboard/{region}/{name}";

function LeagueOfChallengesRouter(): React.Element {
  const [masteryData, setMasteryData] = useState(null);
  const [challengesData, setChallengesData]: ChallengesData = useState(null);
  const [lowHangingFruitsData, setLowHangingFruitsData] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [staticSummonerData, setStaticSummonerData] = useState(null);

  const [isBadSummoner, setIsBadSummoner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("13.11.1");

  useEffect(() => {
    fetch("https://ddragon.leagueoflegends.com/api/versions.json")
    .then((res) => res.json())
    .then((res) => {
      setCurrentVersion(res[0]);
    });
  }, []);

  const fetchStats = (url) => {
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.success === false) {
          setIsBadSummoner(true);
        } else {
          setLowHangingFruitsData(res.fruits);
          setMasteryData(res.mastery);
          setChallengesData(res.challenges);
          setIsBadSummoner(false);
        }
      })
      .catch(error => setIsBadSummoner(true))
    };

    const fetchLeaderboardStats = (url) => {
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          if (res.success === false) {
            setIsBadSummoner(true);
          } else {
            setStaticSummonerData(res.static_data);
            setLeaderboardData(res.challenges);
            setIsLoading(false);
            setIsBadSummoner(false);
          }
        })
        .catch(error => setIsBadSummoner(true))
        .finally(() => setIsLoading(false))
      };
  

    const lookupSummoner = (
      lookupTarget => {
        setIsLoading(true);
        fetchStats(
          API_URL
            .replace("{name}", encodeURIComponent(lookupTarget.summoner))
            .replace("{region}", lookupTarget.region)
        );
        fetchLeaderboardStats(LEADERBOARD_URL.replace("{name}", encodeURIComponent(lookupTarget.summoner)).replace("{region}", lookupTarget.region)
        );

    });

  const mainComponent = (
    <ChampionDataContext.Provider value={{version: currentVersion}}>
      <ChallengesDataContext.Provider value={
        {
          lowHangingFruit: lowHangingFruitsData, 
          challengesData: challengesData, 
          masteryData: masteryData,
          leaderboardData: leaderboardData,
          staticSummonerData: staticSummonerData,
        }}>
        <LeagueOfChallenges
          resetLookupTarget={async () => {
            setIsBadSummoner(false);
          }}
          onLookupSummoner={lookupSummoner}
          isBadSummoner={isBadSummoner}
          isLoading={isLoading}         
        />
      </ChallengesDataContext.Provider>
    </ChampionDataContext.Provider>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={mainComponent}>
        <Route path=":region" />
        <Route path=":region/:summoner" />
        <Route path=":region/:summoner/:challenge" />
      </Route>
    ),
  );

  return <RouterProvider router={router} />;
}

export default LeagueOfChallengesRouter;
