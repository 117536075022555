import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChallengeDetailsHeaderCard from '../ChallengeDetailsHeaderCard.react';

type Props = {
  current_level: {level: string, value: number},
  next_level: {level: string, value: number},
  description: string,
  missingChamps: number,
  totalMasteryPointsMissing: Number,
  iconURL: String,
};

function CatchEmAllHeaderCard(props: Props): React.Element {
  const curr_level = props.current_level == null ? {level: "None", value: 0} : props.current_level;
  const next_level = props.next_level;

  const curr_level_formatted =
    curr_level.level[0] + curr_level.level.substring(1).toLowerCase();
  const next_level_formatted =
    next_level.level[0] + next_level.level.substring(1).toLowerCase();

  return (
    <ChallengeDetailsHeaderCard 
      title="Catch 'em All" 
      currentLevel={ 
        <div>
          Current Level: <Box className={curr_level.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{curr_level_formatted}</Box>
        </div>
      }
      nextLevel={
        <div>
          Minimum bar to reach <Box className={next_level.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{next_level_formatted}: {Number(props.next_level.value).toLocaleString()}</Box>
        </div>
      }
      moreDetails={
        <Typography variant="body2" gutterBottom component="div">
          Champions below the bar: <Box fontWeight='bold' display='inline'>{Number(props.missingChamps).toLocaleString()}</Box>, total mastery points missing:  <Box fontWeight='bold' display='inline'>{Number(props.totalMasteryPointsMissing).toLocaleString()}</Box>
        </Typography>
      }
      description={props.description}
      icon={
        curr_level.level !== "None" ? 
          <div className='ChallengeDetailsIconContainer'>
            <img className={'LHFChallengeIcon ' + curr_level.level.toUpperCase() + ' ratedBoxshadow'}  src={props.iconURL} alt=''/>
          </div>
        : null
      }
      borderClass={"ratedBorder " + curr_level.level.toUpperCase()}
    />
  );
}

export default CatchEmAllHeaderCard;
