import LowHangingFruitHeaderCard from './LowHangingFruitHeaderCard.react';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function LowHangingFruitChallengeView(props: Props): React.Element {
  const { lowHangingFruit } = useContext(ChallengesDataContext);

  const fruitData = Object.keys(lowHangingFruit).map(key => 
   ({
    name: lowHangingFruit[key].challenge_name,
    currentLevel: lowHangingFruit[key].current_level,
    description: lowHangingFruit[key].description,
    nextLevel: lowHangingFruit[key].next_level,
    percentile: lowHangingFruit[key].percentile,
    iconURL:lowHangingFruit[key].current_level_icon,
   })
  );

return (
  <div className={"challengeViewContainer"}>
    <LowHangingFruitHeaderCard />
    <div className={"LHFCardContainer"}>
      {fruitData.sort((a, b) => {
        const currentLevelA = a.currentLevel == null ? {level: "None", value: 0} : a.currentLevel;
        const currentLevelB = b.currentLevel == null ? {level: "None", value: 0} : b.currentLevel;
        return GAINED_POINTS[currentLevelB.level.toLowerCase()] - GAINED_POINTS[currentLevelA.level.toLowerCase()];
      }).map(fruit => 
        <ChallengeCard 
          title={fruit.name} 
          currentLevel={fruit.currentLevel} 
          nextLevel={fruit.nextLevel}
          description={fruit.description}
          percentile={fruit.percentile}
          iconURL={fruit.iconURL} 
          key={fruit.name} />
      )}
    </div>
  </div>
);
}

const GAINED_POINTS = {"none": 0, "bronze": 5, "silver": 5, "gold": 10, "platinum": 15, "diamond": 20, "master": 40};

function ChallengeCard(
props: 
  {
    title: string, 
    currentLevel: {level: string, value: number}, 
    nextLevel: {level: string, value: number},
    description: string,
    percentile: Number,
    iconURL: String,
  },
): React.Element {
const currentLevel = props.currentLevel == null ? {level: "None", value: 0} : props.currentLevel;
const nextLevel = props.nextLevel;

const currentLevelFormatted =
  currentLevel.level[0] + currentLevel.level.substring(1).toLowerCase();

const nextLevelFormatted = nextLevel.level[0] + nextLevel.level.substring(1).toLowerCase();
const addedPoints = GAINED_POINTS[nextLevel.level.toLowerCase()];
const icon = (Math.floor(Math.random() * 500) === 250) ? 'https://ddragon.leagueoflegends.com/cdn/13.18.1/img/champion/Velkoz.png' : props.iconURL;

return (
  <Card className={"LHFCard " + currentLevel.level.toUpperCase() + ' ratedBorder'}>
    {currentLevel.level.toLowerCase() !== "none" ? <div className='LHFChallengeIconContainer'>
      <img className={'LHFChallengeIcon ' + currentLevel.level.toUpperCase() + ' ratedBoxshadow'}  src={icon} alt=''/>
    </div> : null}
    <CardContent className='LHFCardInner'>
      <Typography gutterBottom variant="h5" component="div">
        {props.title}
      </Typography>
      <Typography variant="body2" gutterBottom display='block' component="div">
        {props.description}
      </Typography>
      <Typography variant="h6" component="div">
        <div>
          Current Level: <Box className={currentLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{currentLevelFormatted} ({Number(currentLevel.value).toLocaleString()})</Box>
        </div>
      </Typography>
      <Typography variant="h6" component="div">
        <div>
          To advance to <Box className={nextLevel.level.toUpperCase() + " ratedText highlightedValue"} fontWeight='bold' display='inline'>{nextLevelFormatted}: {Number((nextLevel.value - currentLevel.value)).toLocaleString()}</Box>
        </div>
      </Typography>
      <Typography variant="subtitle2" component="div">
        <div>
          (+{addedPoints} points)
        </div>
      </Typography>
    </CardContent>
  </Card>
  );
}

export default LowHangingFruitChallengeView;
