import React from 'react';
import {useState, useMemo, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getComparator } from '../mastery-list/SortingUtils';
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableSortLabel
} from '../mastery-list/StylizedTableComponentsUtil';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {getChampionPortraitURL} from "../../top-level/ChampionUtils";
import ChampionDataContext from '../../top-level/ChampionDataContext.react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';
import {useContext} from 'react';

type Props = {
  summoner: string,
  nextChallengeBar: number,
  setMissingChamps: () => void,
  setTotalMasteryPointsMissing: () => void,
}

function CatchEmAllMasteryList(props: Props): React.Element {
  const { masteryData } = useContext(ChallengesDataContext);
  const { version } = useContext(ChampionDataContext);

  const missingStyle = {backgroundColor: "#f97373"};
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('points');
  const [onlyMissing, setOnlyMissing] = useState(false);


  const top150 = useMemo(
    () => masteryData.slice(0, 150).map(row => (row.champion_id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const createSortHandler =
    (property) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

  const sortedRows = useMemo(
    () =>
      masteryData
      .sort(getComparator(order, orderBy, "name"))
      .map(
        row =>
          ({
            id: row.champion_id,
            name: row.name,
            points: row.points,
            // Add field for challenge highlighting
            // instead of calculating on render
            shouldHighlight:
              top150.includes(row.champion_id)
              && parseInt(row.points, 10) < props.nextChallengeBar,
          })
      ),
    [masteryData, props.nextChallengeBar, order, orderBy, top150],
  );

  //Send the # of champions back up to display in the card
  useEffect(
    () => props.setMissingChamps(sortedRows.filter(r => r.shouldHighlight).length),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  //Send the # of missing mastery points back up to display in the card
  useEffect(
    () => props.setTotalMasteryPointsMissing(sortedRows.filter(r => r.shouldHighlight).map(r => r.points).reduce((accumulator, currentValue) => accumulator + (props.nextChallengeBar - currentValue), 0)),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <div className="masteryTable">
      <>
        <Title summoner={props.summoner}  />
        <FormGroup className={"masteryListCheckbox"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyMissing}
                onChange={checked => setOnlyMissing(checked.target.checked)}
                style ={{
                  color: "#f97373",
                }}
              />}
            label="Only show champions below the bar" />
        </FormGroup>
        <TableContainer >
          <Table sx={{ color: "white", minWidth: 700, border: "1px solid white" }} aria-label="customized table" size="small">
            <TableHead>
              <TableRow>
               <StyledTableCell/>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell sortDirection={order}>
                  <StyledTableSortLabel
                    direction={order}
                    active={true}
                    onClick={createSortHandler("points")}>
                    Mastery
                  </StyledTableSortLabel>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows
                .filter(
                  row => onlyMissing === true ? row.shouldHighlight === true : true
                )
                .map((row) => (
                <StyledTableRow
                  key={row.name}
                  style={row.shouldHighlight ? missingStyle : {color: "white"}}>
                  <StyledTableCell style={{width: 24}}>
                    <div className={"masteryListChampionPortrait"}>
                      <img 
                        src={getChampionPortraitURL(row.name, version)} 
                        className={"masteryListChampionPortrait"} 
                        alt={row.name + "'s champion portrait"} 
                      />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" style={row.shouldHighlight ? {} : {color: "white"}}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell style={row.shouldHighlight ? {} : {color: "white"}}>{Number(row.points).toLocaleString()}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
}

function Title(props: {summoner: string}): React.Element {
  return (
    <div className="tableTitle">
      <h2>
        {"Champion Mastery for " + props.summoner}
      </h2>
      <div style={{display: "inline-flex"}}>
        <div className={"titleHintColorbox"}/>
        <h4 style={{lineHeight: "24px"}}>
          - Champions that are below the bar
        </h4>
      </div>
    </div>
  );
}

export default CatchEmAllMasteryList;
