import { createContext } from 'react';

// Create a new context
const ChallengesDataContext = createContext(
  {
    lowHangingFruit: {},
    masteryData: {},
    challengesData: {},
    leaderboardData: {},
    staticSummonerData: {},
  },
);

export default ChallengesDataContext;