function descendingComparator(a, b, orderBy, secondaryOrder) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return b[secondaryOrder] > a[secondaryOrder] ? 1 : -1;
}

export function getComparator(
  order,
  orderBy,
  secondaryOrder,
): (
  a: { [key]: number | string },
  b: { [key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, secondaryOrder)
    : (a, b) => -descendingComparator(a, b, orderBy, secondaryOrder);
}
