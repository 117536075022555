import React from 'react';
import {useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';

type Props = {
  onLookup: string => void,
  region: string,
  currentSummoner?: string,
  onBack: () => void,
};

const VALID_REGIONS = ['eun1','euw1','na1','kr1'];

function SummonerLookupSearchBar(props: Props) {
  const [inputValue, setInputValue] = useState(props.currentSummoner == null ? "" : props.currentSummoner);
  const [region, setRegion] =
    useState(
      VALID_REGIONS.includes(props.region) ? props.region : "eun1"
    );

  const handleChange = (event: SelectChangeEvent) => {
    setRegion(event.target.value);
  };

  return (
    <div className={"summonerSearchBar"}>
      <div className={"summonerInputInSearchbarContainer"}>
        <IconButton
          onClick={() => props.onBack()}
          className={"BackButtonColor"}
          sx={ { borderRadius: "50%", marginRight: "8px", maxWidth: '52px', maxHeight: '52px', minWidth: '52px', minHeight: '52px' }}>
          <ArrowBack />
        </IconButton>
        <input
          value={inputValue}
          type="text"
          className={"summonerInputInSearchbar"}
          placeholder="Summoner name"
          onChange={event => setInputValue(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter' && event.target.value !== "") {
              props.onLookup({summoner: event.target.value, region: region})
            }
          }} />
          <Select
            value={region}
            onChange={handleChange}
            className="searchBarRegionSelector"
            style={{ height: 40 }}>
            <MenuItem value={"eun1"}>EUN</MenuItem>
            <MenuItem value={"euw1"}>EUW</MenuItem>
            <MenuItem value={"na1"}>NA</MenuItem>
            <MenuItem value={"kr"}>KR</MenuItem>
          </Select>
      </div>
    </div>
  );
}

export default SummonerLookupSearchBar;
