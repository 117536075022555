import React from 'react';

type Props = {
  progressPCT: number,
  points: Number,
  level: number,
  colorClass: String,
};

const REQUIREMENTS = [1800, 6000, 12600, 21600];

function MasteryRowProgress(props: Props): React.Element {
  if (props.progressPCT == null) {
    return null;
  }
  const innerColor = props.colorClass ?? "";
  const title = props.level == null ? '' : props.points + "/" + REQUIREMENTS[props.level - 1]; 

  return (
    <div className={"progressContainer"} title={title}>
      <div className={"progressBar"}>
        <div className={"progressBarInner " + innerColor} style={{'width': (props.progressPCT * 100) + '%'}}/>
      </div>
    </div>
  );
}

export default MasteryRowProgress;
