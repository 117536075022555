import React from 'react';
import ChallengeDetailsHeaderCard from '../ChallengeDetailsHeaderCard.react';
import Typography from '@mui/material/Typography';

function LowHangingFruitHeaderCard(): React.Element {
  return (
    <ChallengeDetailsHeaderCard 
      title="Low Hanging Fruit" 
      moreDetails={
        <Typography variant="body2" gutterBottom component="div">
          A list of challenges that are close to being complete
        </Typography>
      }
      borderClass={"ratedBorder NONE"}
    />
  );
}

export default LowHangingFruitHeaderCard;
