import React from 'react';
import LeaderboardGridItem from "./LeaderboardGridItem.react";
import {useState, useEffect} from 'react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
  challengesToShow: [],
  shownGamers: Array<String>,
};

const PAGE_SIZE = 10; // Number of components per page

function LeaderboardChallengesGrid(props: Props): React.Element {
  const [page, setPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    const updatePaginatedData = () => {
      const startIndex = (page - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      setPaginatedData(props.challengesToShow.slice(0, endIndex));
    };
    updatePaginatedData();
  }, [page, props.challengesToShow]);

  const handleScroll = () => {
    try {
      const container = document.getElementsByClassName('mainContentContainer')[0];
      if (Math.floor(container.scrollHeight - container.scrollTop) <= container.clientHeight) {
        setPage(prevPage => prevPage + 1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    try {
      const container = document.getElementsByClassName('mainContentContainer');
      container[0].addEventListener('scroll', handleScroll);
      return () => {
        container.length > 0 && container[0]?.removeEventListener('scroll', handleScroll);
      };
    } catch (e) {
      return;
    }
  }, []); // Empty dependency array ensures this effect runs only once

  return (
      <div className={"LeaderboardChallengeGrid"}> 
        {paginatedData.map((l, index) => 
          <LeaderboardGridItem challenge={l} key={index} currentSummoner={props.lookupTarget.summoner} shownGamers={props.shownGamers} />
        )}
      </div>
    );
}

export default LeaderboardChallengesGrid;
