import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type Props = {
  title: String,
  description?: string,
  borderClass: String,
  currentLevel?: React.Element<div>,
  nextLevel?: React.Element<div>,
  moreDetails?: ?React.Element<div>,
  icon?: React.Element<div>,
};

function ChallengeDetailsHeaderCard(props: Props): React.Element {
  return (
    <Card className={"ChallengeDetailsCard " + props.borderClass}>
      {props.icon}
      <CardContent>
        <Typography gutterBottom variant="h4" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" component="div">
          {props.currentLevel}
        </Typography>
        <Typography variant="body2" component="div" gutterBottom>
          {props.nextLevel}
        </Typography>
        {props.moreDetails && props.moreDetails}
        <Typography variant="body2">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ChallengeDetailsHeaderCard;
