import React from 'react';
import tokenImg from '../../img/masteryToken.png';

type Props = {
  level: number,
  tokens: number
};

const REQUIREMENTS = [2, 3];

function MasteryTokenProgress(props: Props): React.Element {
  const totalAmount = REQUIREMENTS[props.level-5];
  
  return (
    <div className={"masteryTokensContainer"}>
      {Array.from(Array(totalAmount)).map((_, index) => {
        const isMissing = index + 1 > props.tokens;
        return (
          <div className={isMissing ? "masteryTokenContainerMissing" : "masteryTokenContainer"} key={index}>
            <img 
              src={tokenImg} 
              className={isMissing ? "masteryTokenImageMissing" : "masteryTokenImage"} 
              alt={"mastery token"} 
            />
          </div>
        );
      })}
    </div>    
  );
}

export default MasteryTokenProgress;
