import CatchEmAllMasteryList from './CatchEmAllMasteryList.react';
import CatchEmAllHeaderCard from './CatchEmAllHeaderCard.react';
import React from 'react';
import {useState, useContext} from 'react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function CatchEmAllChallengeView(props: Props): React.Element {
  const { challengesData } = useContext(ChallengesDataContext);

  const curr_level = challengesData.catch_em_All.current_level;
  const next_level = challengesData.catch_em_All.next_level;
  const [missingChamps, setMissingChamps] = useState(null);
  const [totalMasteryPointsMissing, setTotalMasteryPointsMissing] = useState(null);


  return (
    <div className={"challengeViewContainer"}>
      <CatchEmAllHeaderCard
        current_level={curr_level}
        next_level={next_level}
        description={challengesData.catch_em_All.description}
        missingChamps={missingChamps} 
        totalMasteryPointsMissing={totalMasteryPointsMissing}
        iconURL={challengesData.catch_em_All.current_level_icon}/>
      <CatchEmAllMasteryList
        summoner={props.lookupTarget.summoner}
        nextChallengeBar={challengesData.catch_em_All.next_level.value}
        setMissingChamps={setMissingChamps}
        setTotalMasteryPointsMissing={setTotalMasteryPointsMissing}
      />
    </div>
  );
}

export default CatchEmAllChallengeView;
