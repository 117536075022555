import React from 'react';
import LeaderboardAccountHeader from './LeaderboardAccountHeader.react';
import LeaderboardChallengeSection from './LeaderboardChallengeSection.react';

type SummonerAndRegion = {
  summoner: string,
  region: string,
};

type Props = {
  lookupTarget: SummonerAndRegion,
};

function LeaderboardView(props: Props): React.Element {
  return (
    <div className={"LeaderboardContainer"}>
      <LeaderboardAccountHeader lookupTarget={props.lookupTarget} />
      <LeaderboardChallengeSection lookupTarget={props.lookupTarget} />
    </div>
  );
}

export default LeaderboardView;
