// @flow

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type Props = {
  onClick: () => void,
  label: string,
  currentLevel?: string,
  currentLevelIconURL?: string,
};

function ChallengeCard(props: Props): React.Element<any> {
  const currLevel = props.currentLevel != null && props.currentLevel !== "NONE" ? props.currentLevel : null;

  return (
    <Card className={"ChallengeSelectionCard ratedBorder " + (currLevel?.toUpperCase() ?? 'NONE')} onClick={props.onClick} href="#" target="_blank">
      {currLevel != null && props.currentLevelIconURL != null ? 
        <div className='ChallengeSelectionIconContainer'>
          <img className={'LHFChallengeIcon ' + currLevel.toUpperCase() + ' ratedBoxshadow'}  src={props.currentLevelIconURL} alt=''/>
        </div>
        : null}
    <CardContent className='LHFCardInner'>
      <Typography gutterBottom variant="h5" component="div">
        {props.label}
      </Typography>
    </CardContent>
  </Card>
  );
}

export default ChallengeCard;
