// @flow 

import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type Props = {
  onLookup: {summoner: string, region: string} => void,
  region: string,
};

const VALID_REGIONS = ['eun1','euw1','na1','kr1'];

function SummonerLookup(props: Props): React.Element<any> {
  const [inputValue, setInputValue] = useState("");
  const [region, setRegion] =
    useState(
      VALID_REGIONS.includes(props.region) ? props.region : "eun1"
    );

  const handleChange = (event: any) => {
    setRegion(event.target.value);
  };

  return (
    <div className={"summonerInputContainer"}>
      <input
        value={inputValue}
        className={"summonerInput"}
        type="text"
        placeholder="Summoner name"
        onChange={event => setInputValue(event.target.value)}
        onKeyDown={event => {
          if (event.key === 'Enter' && event.target.value !== "") {
            props.onLookup({summoner: event.target.value, region: region})
          }
        }} />
      <Select
        value={region}
        onChange={handleChange}
        className="regionSelector">
        <MenuItem value={"eun1"}>EUN</MenuItem>
        <MenuItem value={"euw1"}>EUW</MenuItem>
        <MenuItem value={"na1"}>NA</MenuItem>
        <MenuItem value={"kr"}>KR</MenuItem>
      </Select>
      <div className={"summonerNameLookupButton"}>
        <Button
          className={"lookupButton"}
          onClick={event =>
            props.onLookup({summoner: inputValue, region: region})
          }
          variant="contained">
          Look me up
        </Button>
      </div>
    </div>
  );
}

export default SummonerLookup;
