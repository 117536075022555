import React from 'react';
import {useMemo, useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableSortLabel
} from '../mastery-list/StylizedTableComponentsUtil';
import { getComparator } from '../mastery-list/SortingUtils';
import {getChampionPortraitURL} from "../../top-level/ChampionUtils";
import MasteryRowProgress from '../mastery-list/MasteryRowProgress.react'
import ChampionDataContext from '../../top-level/ChampionDataContext.react';
import ChallengesDataContext from '../../top-level/ChallengesDataContext.react';

type Props = {
  summoner: string,
}

const MASTERY_REQUIREMENTS = [1800, 6000, 12600, 21600];

function MasterYourselfMasteryList(props: Props): React.Element {
  const { masteryData } = useContext(ChallengesDataContext);
  const { version } = useContext(ChampionDataContext);

  const level4Champions = useMemo(
    () => masteryData.filter(champion => champion.level <= 4).slice(0, props.amountToShow),
    [props.amountToShow, masteryData],
  );

  const sortedRows = useMemo(
    () =>
      level4Champions
      .sort(getComparator("desc", "points", "name"))
      .map(
        row =>
          ({
            id: row.champion_id,
            name: row.name,
            points: row.points,
            level: row.level,
          })
      ),
    [level4Champions],
  );

  return (
    <div className="masteryTable">
      <>
        <Title summoner={props.summoner}  />
        <TableContainer>
          <Table sx={{ color: "white", minWidth: 700, border: "1px solid white" }} aria-label="customized table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell/>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Level</StyledTableCell>
                <StyledTableCell sortDirection={"desc"}>
                  <StyledTableSortLabel
                    direction={"desc"}
                    active={false}>
                    Mastery
                  </StyledTableSortLabel>
                </StyledTableCell>
                <StyledTableCell>Progress</StyledTableCell>
                <StyledTableCell>Points to level 5</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows
                .map((row) =>  (
                <StyledTableRow key={row.name}>
                  <StyledTableCell style={{width: 24}}>
                    <div className={"masteryListChampionPortrait"}>
                      <img 
                        src={getChampionPortraitURL(row.name, version)} 
                        className={"masteryListChampionPortrait"} 
                        alt={row.name + "'s champion portrait"} 
                      />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" style={{color: "white"}}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{row.level}</StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{Number(row.points).toLocaleString()}</StyledTableCell>
                  <StyledTableCell><MasteryRowProgress level={4} points={row.points} progressPCT={row.points / MASTERY_REQUIREMENTS[3]}/></StyledTableCell>
                  <StyledTableCell style={{color: "white"}}>{MASTERY_REQUIREMENTS[3] - row.points}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
}

function Title(props: {summoner: string}): React.Element {
  return (
    <div className="tableTitle">
      <h2>
        {"Candidate champions to upgrade for " + props.summoner}
      </h2>
    </div>
  );
}

export default MasterYourselfMasteryList;
