import './App.css';
import LeagueOfChallengesRouter from './top-level/LeagueOfChallengesRouter.react';
import React from 'react';

function App(): React.Element {
  return (
    <>
      <div className="appBackground"/>
      <div className="App">
        <LeagueOfChallengesRouter />
      </div>
    </>
  );
}

export default App;
