import LoadingSpinner from './LoadingSpinner.react';
import SummonerFetchError from '../summoner/SummonerFetchError.react';
import SummonerLookup from '../summoner/SummonerLookup.react';
import SummonerLookupSearchBar from '../summoner/SummonerLookupSearchbar.react';
import MainChallengeView from "../main-view-challenges/MainChallengeView.react";
import React from 'react';
import {
  useParams,
  useNavigate,
} from "react-router-dom";
import {useEffect, useContext} from 'react';
import ChallengesDataContext from '../top-level/ChallengesDataContext.react';

type Props = {
  onLookupSummoner: () => void,
  isBadSummoner: boolean,
  isLoading: boolean,
};

function LeagueOfChallenges(props: Props): React.Element {
  const { masteryData } = useContext(ChallengesDataContext);

  const navigate = useNavigate();
  const params = useParams();

  const lookupNew = (newLookupTarget) => {
    props.onLookupSummoner(newLookupTarget);
    navigate("/" + newLookupTarget.region + "/" + newLookupTarget.summoner);
  };

  const onBack = () => window.history.back();

  const lookupTarget =
    params == null
    ? null
    : {region: params.region, summoner: params.summoner};

  useEffect(() => {
    // Fetch data once we've received one from the URL param
    if (lookupTarget && lookupTarget.summoner) {
      props.onLookupSummoner(
        {region: lookupTarget.region, summoner: lookupTarget.summoner}
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }}, []);

  // Loading state
  if (props.isLoading) {
    return (
      <>
        <SummonerLookupSearchBar onLookup={lookupNew} region={params.region} currentSummoner={lookupTarget.summoner} onBack={onBack} />
        <LoadingSpinner />
      </>
    );
  }

  // Error fetching summoner
  if (props.isBadSummoner) {
    return (
      <>
        <SummonerLookupSearchBar onLookup={lookupNew} region={params.region} onBack={onBack} />
        <SummonerFetchError
          label="Take me back"
          onClick={() => {
            props.resetLookupTarget();
            // navigate to "/" so that old summoner doesn't show in the url
            navigate('/');
          }}
          summoner={lookupTarget.summoner}/>
        </>
      );
  }

  // Show lookup page
  if (lookupTarget == null || lookupTarget.summoner == null || masteryData == null) {
    return <SummonerLookup onLookup={lookupNew} region={params.region} />;
  }

  // Show main page
  return (
    <>
      <SummonerLookupSearchBar onLookup={lookupNew} region={params.region} currentSummoner={lookupTarget.summoner} onBack={onBack} />
      <div className="mainContentContainer">
        <MainChallengeView lookupTarget={lookupTarget} challenge={params.challenge} />
      </div>
    </>
  );
}

export default LeagueOfChallenges;
