export function getChampionPortraitURL(name: string, version: string): string {
  let parsedName;
  switch (name) {
    case 'Wukong':
      parsedName = "MonkeyKing";
      break;
    case 'Nunu & Willump':
      parsedName = "Nunu";
      break;
    case 'LeBlanc':
      parsedName = "Leblanc";
      break;
    case "Kog'Maw":
      parsedName = "KogMaw";
      break;
    case "Rek'Sai":
      parsedName = "RekSai";
      break;
    case "Dr. Mundo":
      parsedName = "DrMundo";
      break;
    case "Renata Glasc":    
      parsedName = "Renata";
      break;
    case "K'Sante":    
      parsedName = "KSante";
      break;
    default:
      parsedName = name.replaceAll(" ", "");
  }

  if (parsedName.includes("'")) {
    parsedName = parsedName.replaceAll("'", "");
    parsedName = parsedName.charAt(0).toUpperCase() + parsedName.slice(1).toLowerCase();
  }

  return `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${parsedName}.png`;
}
